import { Component, Vue } from 'vue-property-decorator';
import { includeUserTypes, UserRole, UserType } from '@/models/User';
import UserManagement from '@/components/UserManagement/UserManagement.vue';

@Component<SimpleUserManagement>({
  components: {
    UserManagement,
  },
})
export default class SimpleUserManagement extends Vue {
  // #region Class properties

  protected userTypes: UserType[] = includeUserTypes([UserRole.SIMPLE, UserRole.SIMPLE_PLUS]);

  // #endregion
}
