var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("UserManagement", {
    attrs: {
      isSimpleUserManagement: true,
      title: "Simple Gebruikersbeheer",
      userTypes: _vm.userTypes
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }